import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The accordion component that allows users to expand and collapse content sections. It is commonly used to display a large amount of information in a compact space, making it easier for users to scan and consume content.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-guideline-anatomy.svg",
      "alt": "accordion - guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Accordion Item`}</strong>{`: Container for collapsed or expanded panel of accordion.`}</li>
      <li parentName="ol"><strong parentName="li">{`Chevron Icon`}</strong>{`: To indicates states if the panel is open or closed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Title`}</strong>{`: The heading text for the information being shown in the content section.`}</li>
      <li parentName="ol"><strong parentName="li">{`Content`}</strong>{`: This section is where users can find more information and details about the container’s subject.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The accordion component is used to create expandable and collapsible sections of content. This can be useful in situations where you have a large amount of information to display, but want to keep the interface compact and easy to navigate.`}</p>
    <p><strong parentName="p">{`Common places buttons appear`}</strong>{` : Often used in FAQs, product descriptions, and other situations where users need to access detailed information.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        When you have a large amount of content that needs to be displayed in a
        compact space.
      </li>
      <li>
        When you want to create a clean and organized interface that is easy to
        navigate.
      </li>
      <li>When you want to group related content together in a logical way.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When you only have a small amount of content to display.</li>
      <li>When you want to display all content sections at once.</li>
      <li>
        When the content is not related and does not need to be grouped
        together.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`The accessibility of the Accordion component is an important consideration to ensure that users with disabilities or impairments are able to interact with the component effectively. Here are some accessibility considerations for the Accordion component:`}</p>
    <ol>
      <li parentName="ol">{`Keyboard Navigation: Users should be able to navigate through the accordion using only the keyboard, without requiring a mouse or other pointing device. This means that the accordion should be operable using the Tab, Arrow, and Enter keys, and should have clear keyboard focus indicators.`}</li>
      <li parentName="ol">{`Screen Reader Support: Users who rely on screen readers to access content should be able to navigate and understand the accordion structure and content. This can be achieved by ensuring that appropriate ARIA roles and attributes are used to identify the accordion and its elements, and by providing descriptive labels and instructions.`}</li>
      <li parentName="ol">{`Color Contrast: The accordion should have sufficient color contrast between the background and text to ensure readability for users with low vision or color blindness. This can be achieved by using a color contrast ratio of at least 4.5:1 for text and interactive elements.`}</li>
      <li parentName="ol">{`Responsive Design: The accordion should be designed to work effectively across a range of screen sizes and device types. This means that the accordion should be responsive and scalable, with clear and legible text and buttons on smaller screens.`}</li>
      <li parentName="ol">{`Clear and Concise Content: The content within the accordion should be clear and concise, with headings and labels that accurately describe the content that is revealed when the accordion is expanded. This can help users with cognitive disabilities to understand the purpose of the accordion and its content.`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use clear Heading title or section for more accordion" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-a11y-dos.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use more than 8 accordion to make hard to find title" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-a11y-donts.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Accordion :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The content should be organized into logical sections that can be easily expanded and collapsed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use headings and subheadings to break up the content and make it easier to scan.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use bullet points or numbered lists to make the content more digestible.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Include relevant images or other visual aids to help illustrate the content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use clear and concise language for the header labels.`}</p>
      </li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use clear and concise language for the header labels." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-content-dos.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use unclear or misleading language for the header labels." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-content-donts.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Utilize the content section to describe a subject in details." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-content-dos-two.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Content section to only describe a title with one or less sentence." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-content-donts-two.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      